import { AbstractRecorder } from "./abstract-recorder";
import { MediaRecorderComponent } from "./media-recorder.component";
import { HardwareFailureComponent } from "./hardware-failure.component";
import { DeviceService } from "app/shared/device.service";
import { Router } from "@angular/router";
import * as RecordRTC from 'recordrtc';
declare var MediaRecorder;

export class WebRecorder extends AbstractRecorder {
    component: MediaRecorderComponent;
    userStream: MediaStream;
    desktopStream: MediaStream;
    userStreamPromise: Promise<any>;
    desktopStreamPromise: Promise<any>;

    constructor(component, deviceService: DeviceService, router: Router) {
        console.log('setting up a web recorder.');
        super(component, deviceService, router);
        // set the initial state of the player
    }

    connectAndPreview() {
        return new Promise<null>((resolve, reject) => {
            this.component.setPlayerMode('preview');
            const mediaConstraints: { audio: boolean; video?: any } =
                this.component.type === 'video' || this.component.type === 'screenAndCamera' ? { video: true, audio: true } : { audio: true };

            if (this.component.type === 'video' || this.component.type === 'screenAndCamera') {
                if (navigator.mediaDevices.getSupportedConstraints()['facingMode']) {
                    mediaConstraints.video = { facingMode: 'user' };
                }
            }

            this.userStreamPromise = navigator.mediaDevices.getUserMedia(mediaConstraints);

            if (this.component.captureSpeakerAndMicrophone) {
                const deviceMediaConstraints = {
                    video: true,
                    audio: {
                        echoCancellation: true,
                        noiseSuppression: true,
                        sampleRate: 44100
                    },
                    selfBrowserSurafce: 'include',
                    preferCurrentTab: true
                };
                this.desktopStreamPromise = (navigator.mediaDevices as any).getDisplayMedia(deviceMediaConstraints);
                this.startSpeakerAndMicrophoneRecording(true);
                resolve(null);
            } else {
                if (this.component.type?.includes('screen')) {
                    const deviceMediaConstraints = {
                        video: {
                            displaySurface: 'monitor',
                            selfBrowserSurface: 'exclude'
                        },
                        audio: false,
                    };
                    this.desktopStreamPromise = (navigator.mediaDevices as any).getDisplayMedia(deviceMediaConstraints);
                    this.startScreenAndCameraRecording(true);
                    resolve(null);
                }  else {
                    this.userStreamPromise.then(stream => {
                        this.userStream = stream;
                        this.successCameraCallback(stream);
                        resolve(null);
                    }, error => {
                        // Don't reject here because the error callback handles it all.
                        this.errorCameraCallback(error);
                    });
                }
            }
        });
    }

    mergeAudioStreams(desktopStream, microphoneStream): MediaStreamTrack[] {
        const context = new AudioContext();
        const destination = context.createMediaStreamDestination();
        let hasDesktop = false;
        let hasMicrophone = false;

        if (desktopStream && desktopStream.getAudioTracks().length > 0) {
            // Create a desktop audio source
            const desktopSource = context.createMediaStreamSource(desktopStream);
            const desktopGain = context.createGain();
            desktopGain.gain.value = 0.7;
            desktopSource.connect(desktopGain).connect(destination);
            hasDesktop = true;
        }

        if (microphoneStream && microphoneStream.getAudioTracks().length > 0) {
            // Create a microphone audio source
            const microphoneSource = context.createMediaStreamSource(microphoneStream);
            const microphoneGain = context.createGain();
            microphoneGain.gain.value = 0.7;
            microphoneSource.connect(microphoneGain).connect(destination);
            hasMicrophone = true;
        }

        return (hasDesktop || hasMicrophone) ? destination.stream.getAudioTracks() : [];
    }

    /**
     * Called when we have connected to the camera successfully
     */
    successCameraCallback(stream: MediaStream) {
        this.log(`Accessed the camera successfully.`);
        this.stream = stream;
        console.log('stream is', stream);
        this.component.player.srcObject = stream;
        this.state = 'ready';
        this.component.ready.emit(true);
    }

    /**
     * Called when we can't access the camera
     */
    errorCameraCallback(error) {
        this.log('There was an error when trying to capture ' + this.component.type);
        if (
            error.name === 'PermissionDeniedError' ||
            error.name === 'PermissionDismissedError' ||
            error.name === 'NotAllowedError'
        ) {
            this.log('Permission denied while attempting to access the camera.');
            this.promptForRetry(error.name);
        } else {
            this.log(`Cannot access the camera. Asking the user to retry. Error name: ${error.name}`);
            this.promptForRetry(error.name);
        }
    }

    errorShareScreenCallback(error) {
        this.log('There was an error when trying to capture ' + this.component.type);
        if (
            error.name === 'PermissionDeniedError' ||
            error.name === 'PermissionDismissedError' ||
            error.name === 'NotAllowedError'
        ) {
            this.log('Permission denied while attempting to access share screen or camera.');
            this.promptForRetry(error.name);
        } else {
            this.log(`Cannot access share screen or camera. Asking the user to retry. Error name: ${error.name}`);
            this.promptForRetry(error.name);
        }
    }

    promptForRetry(reason: string) {
        if (!this.router.url.includes('attempt')) {
            return;
        }

        setTimeout(() => {
            console.log('Opening hardware failure dialog because', { data: { reason: reason } });
            const ref = this.component.dialog.open(HardwareFailureComponent, { data: { type: this.component.type, reason: reason } });
            ref.afterClosed().subscribe(() => {
                this.connectAndPreview();
            });
        }, 0);
    }

    start() {
        super.start();
        this.startTime = Date.now();

        if (this.component.type?.includes('screen')) {
            this.startScreenAndCameraRecording(false);
        } if (this.component.captureSpeakerAndMicrophone) {
            this.startSpeakerAndMicrophoneRecording(false);
        } else {
            this.startCameraRecording();
        }
    }

    startCameraRecording() {
        const options = {
            mimeType: this.getMimeToUse(), // or video/webm\;codecs=h264 or video/webm\;codecs=vp9
            audioBitsPerSecond: 128000,
            videoBitsPerSecond: 512000,
            // bitsPerSecond: 128000 // if this line is provided, skip above two
            disableLogs: false,
            type: 'video',
            recorderType: RecordRTC.MediaStreamRecorder,
            ignoreMutedMedia: false,
        };
        if (this.component.type === 'audio') {
            options.type = 'audio';
            this.log('Set RecordRTC type to audio.');
        }
        this.recordRTC = RecordRTC(this.stream, options);
        this.recordRTC.startRecording();
        this.component.player.srcObject = this.stream;
    }

    startScreenAndCameraRecording(isPreview: boolean) {
        Promise.all([
            this.userStreamPromise,
            this.desktopStreamPromise
        ]).then(streams => {
            const [userStream, desktopStream] = streams;
            this.userStream = userStream;
            this.desktopStream = desktopStream;

            (this.desktopStream as any).width = window.screen.width;
            (this.desktopStream as any).height = window.screen.height;
            (this.desktopStream as any).fullcanvas = true;

            (this.userStream as any).width = 320;
            (this.userStream as any).height = 240;
            (this.userStream as any).top = screen.height - (this.userStream as any).height;
            (this.userStream as any).left = screen.width - (this.userStream as any).width;

            const options = {
                type: 'video',
                mimeType: 'video/webm\;codecs=vp9', // or video/webm\;codecs=h264 or video/webm\;codecs=vp9
                audioBitsPerSecond: 128000,
                videoBitsPerSecond: 512000,
                disableLogs: false,
                ignoreMutedMedia: false,
                previewStream: (stream: MediaStream) => {
                    console.log(`Accessed the camera and screen successfully.`);
                    this.stream = stream;
                    this.component.player.srcObject = this.stream;

                    if (isPreview) {
                        this.state = 'ready';
                        this.component.ready.emit(true);
                    }
                }
            };

            this.recordRTC = RecordRTC([desktopStream, userStream], options);
            this.recordRTC.startRecording();
        }, error => {
            this.log(`Cannot access the camera. Asking the user to retry. Error name: ${error.name}`);
            this.promptForRetry(error.name);
        });
    }

    startSpeakerAndMicrophoneRecording(isPreview: boolean) {
        Promise.all([
            this.userStreamPromise,
            this.desktopStreamPromise
        ]).then(streams => {
            const [userStream, desktopStream] = streams;
            this.userStream = userStream;
            this.desktopStream = desktopStream;

            const tracks = [
                ...userStream.getVideoTracks(),
                ...this.mergeAudioStreams(desktopStream, userStream)
            ];
            const mixedStream = new MediaStream(tracks);

            (this.desktopStream as any).width = 0;
            (this.desktopStream as any).height = 0;

            if (this.component.type === 'video') {
                (mixedStream as any).width = userStream.getVideoTracks()[0].getSettings().width;
                (mixedStream as any).height = userStream.getVideoTracks()[0].getSettings().height;
                (mixedStream as any).fullcanvas = true;
            }

            const options = {
                type: 'video',
                mimeType: 'video/webm\;codecs=vp9', // or video/webm\;codecs=h264 or video/webm\;codecs=vp9
                audioBitsPerSecond: 128000,
                videoBitsPerSecond: 512000,
                disableLogs: false,
                ignoreMutedMedia: false,
                previewStream: (previewStream: MediaStream) => {
                    console.log(`Accessed the camera and screen successfully.`);
                    this.stream = previewStream;
                    this.component.player.srcObject = this.stream;

                    if (isPreview) {
                        this.state = 'ready';
                        this.component.ready.emit(true);
                    }
                }
            }
            this.recordRTC = RecordRTC([desktopStream, mixedStream], options);
            this.recordRTC.startRecording();
        }, error => {
            this.component.cancelRecorder.emit();
        });
    }

    stop() {
        super.stop();
        if (this.recordRTC) {
            const recordRTC = this.recordRTC;
            recordRTC.stopRecording(this.processWebMedia.bind(this));
        }

        clearInterval(this.interval);
    }

    /**
    * Called when we have received a video/audio file
    * Warning: This is run outside the Angular zone.
    */
    processWebMedia(audioVideoWebMURL) {
        this.log(this.component.type + ' captured');
        const recordRTC = this.recordRTC;
        // Adding #t=0.02 starts the video from 0.02s instead of 0 which helps to show a thumbnail for mobile apps
        console.log('audioVideoWebMURL', audioVideoWebMURL);
        this.component.player.src = audioVideoWebMURL + '#t=0.02';
        this.component.setPlayerMode('content');

        this.component.zone.run(() => {
            const recordedBlob: Blob = recordRTC.getBlob();
            this.log('Got blob in processVideo method! Blob has type:' + recordedBlob.type);
            this.log('Recorder state is:' + recordRTC.getState());

            this.state = 'recorded';
            const duration = Date.now() - this.startTime;
            this.component.capture.emit([recordedBlob, duration, this.eventLog]);
            super.recordingComplete();
        });
    }

    getMimeToUse() {
        const types = this.component.type === 'audio' ? ['audio/webm', 'audio/ogg'] : ['video/webm'];
        for (const type of types) {
            if (MediaRecorder.isTypeSupported(type)) {
                this.log('This browser supports mime type:' + type);
                return type;
            }
        }
        this.log('Could not find a valid mime type');
        return null;
    }

    rerecord() {
        if (
            this.component.type?.includes('screen') ||
            this.component.captureSpeakerAndMicrophone
        ) {
            this.component.setPlayerMode('preview');
            this.start();
        } else {
            this.connectAndPreview().then(() => {
                this.start();
            });
        }
    }

    destroyTracks() {
        if (this.userStream) {
            const userStream = this.userStream;
            userStream.getAudioTracks().forEach(track => track.stop());
            userStream.getVideoTracks().forEach(track => track.stop());
        }

        if (this.desktopStream) {
            const desktopStream = this.desktopStream;
            desktopStream.getAudioTracks().forEach(track => track.stop());
            desktopStream.getVideoTracks().forEach(track => track.stop());
        }

        if (this.stream) {
            const stream = this.stream;
            stream.getAudioTracks().forEach(track => track.stop());
            stream.getVideoTracks().forEach(track => track.stop());
        }

        this.stream = null;
        this.desktopStream = null;
        this.userStream = null;
    }
}
